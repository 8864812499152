var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-container"},[_c('h2',[_vm._v(_vm._s(_vm.$t('admin.relaunch.add')))]),(!_vm.isLoading)?_c('div',{staticClass:"form-add"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col50"},[_c('ValidationProvider',{staticClass:"input-default",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('admin.relaunch.date')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.date),expression:"date"}],class:{ 'input-error' : errors[0]},attrs:{"type":"date","placeholder":_vm.$t('admin.relaunch.date')},domProps:{"value":(_vm.date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.date=$event.target.value}}}),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col50"},[_c('ValidationProvider',{staticClass:"input-default",attrs:{"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('admin.relaunch.period')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.period),expression:"period"}],class:{ 'input-error' : errors[0]},attrs:{"type":"text","placeholder":_vm.$t('admin.relaunch.period')},domProps:{"value":(_vm.period)},on:{"input":function($event){if($event.target.composing){ return; }_vm.period=$event.target.value}}}),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col33"},[_c('ValidationProvider',{staticClass:"input-default",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ckeckbox-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.call),expression:"call"}],staticClass:"{ 'input-error' : errors[0]}",attrs:{"type":"checkbox","placeholder":_vm.$t('admin.relaunch.call')},domProps:{"checked":Array.isArray(_vm.call)?_vm._i(_vm.call,null)>-1:(_vm.call)},on:{"change":function($event){var $$a=_vm.call,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.call=$$a.concat([$$v]))}else{$$i>-1&&(_vm.call=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.call=$$c}}}}),_c('label',[_vm._v(_vm._s(_vm.$t('admin.relaunch.call')))])]),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col33"},[_c('ValidationProvider',{staticClass:"input-default",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ckeckbox-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sms),expression:"sms"}],staticClass:"{ 'input-error' : errors[0]}",attrs:{"type":"checkbox","placeholder":_vm.$t('admin.relaunch.sms')},domProps:{"checked":Array.isArray(_vm.sms)?_vm._i(_vm.sms,null)>-1:(_vm.sms)},on:{"change":function($event){var $$a=_vm.sms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.sms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.sms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.sms=$$c}}}}),_c('label',[_vm._v(_vm._s(_vm.$t('admin.relaunch.sms')))])]),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col33"},[_c('ValidationProvider',{staticClass:"input-default",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"ckeckbox-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"{ 'input-error' : errors[0]}",attrs:{"type":"checkbox","placeholder":_vm.$t('admin.relaunch.message')},domProps:{"checked":Array.isArray(_vm.message)?_vm._i(_vm.message,null)>-1:(_vm.message)},on:{"change":function($event){var $$a=_vm.message,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.message=$$a.concat([$$v]))}else{$$i>-1&&(_vm.message=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.message=$$c}}}}),_c('label',[_vm._v(_vm._s(_vm.$t('admin.relaunch.message')))])]),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col100"},[_c('ValidationProvider',{staticClass:"input-default",attrs:{"rules":"max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('admin.relaunch.comment')))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],class:{ 'input-error' : errors[0]},attrs:{"placeholder":_vm.$t('admin.relaunch.comment'),"rows":"5"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comment=$event.target.value}}}),_c('span',{staticClass:"notice-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('input',{staticClass:"input-submit",attrs:{"type":"submit"},domProps:{"value":_vm.$t('admin.form.addBtn')}})])]}}],null,false,2078939016)})],1):_c('div',{staticClass:"loader-container"},[_c('loader')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }