<template>
    <div class="tab-container">
        <div class="filter-patient" >
            <div class="filter-container">
                <!-- Search patient -->
                <input type="text" :placeholder="$t('admin.tab.search')" v-model="searchPatient"  class="input-search" />
                <!-- search Date injection -->
                <input type="date" v-model="searchDate" :placeholder="$t('admin.tab.searchDate')"  class="input-search" />
                <!-- search on month -->
                <input type="checkbox" v-model="searchByMonth" class="input-month" v-tooltip="{ content : $t('tooltip.searchMonth')}"/>
                <!-- Filtre doctors -->
                <select v-model="searchByDoctor" class="input-search" v-if="$store.state.USER.infos.role_id === 2">
                    <option value="-1" >{{ $t('admin.tab.searchDoctor') }}</option>
                    <option v-for="(item, idx) in doctors" :key="idx" :value="item.id">{{ `${item.firstname} ${item.lastname}`}}</option> 
                </select>
  
            </div>
            <Export></Export>
        </div>
        <!-- <div class="export-row" v-else>
            <Export></Export>
        </div> -->
        <div class="patient-number-container">
            <div class="patient-filter-box">
                <!-- Afficher tous les patients -->
                <div class="input-default" v-if="$store.state.USER.infos.role_id === 2">
                    <input type="checkbox" @click="changefilter()" :checked="filter" />
                    <label>{{ $t('admin.tab.patientFilter')}}</label>
                </div>
                <!-- Afficher tous les patients en attente après injection -->
                <div class="input-default" v-if="$store.state.USER.infos.role_id === 2">
                    <input type="checkbox" @click="changefilterWaiting()" :checked="filterWaiting" />
                    <label>{{ $t('admin.tab.patientWaiting')}}</label>
                </div>
                <!-- Afficher tous les patients en attente avant injection-->
                <div class="input-default" v-if="$store.state.USER.infos.role_id === 2">
                    <input type="checkbox" @click="changeFilterBefore()" :checked="filterWaitingBefore" />
                    <label>{{ $t('admin.tab.patientWaitingBefore')}}</label>
                </div>
                <!-- Switch archived -->
                <div class="input-default">
                    <input type="checkbox" @click="changeArchived()" :checked="archived" />
                    <label>{{ $t('admin.tab.archived')}}</label>
                </div>
            </div>
            <div class="patient-number-item">
                <div class="patient-number-text">{{ $t('admin.tab.patientNumber')}}</div>
                <div class="patient-number">{{ usersFilter.length }}</div>
            </div>
     
        </div>
        <table>
            <thead>
                <tr>
                    <!-- <th :class="sortClasses('id')" @click="sortBy('id')">ID</th> -->
                    <th :class="sortClasses('lastname')" @click="sortBy('lastname')">{{ $t('admin.tab.lastname')}}</th>
                    <th :class="sortClasses('firstname')" @click="sortBy('firstname')">{{ $t('admin.tab.firstname')}}</th>
                    <th :class="sortClasses('email')" @click="sortBy('email')">{{ $t('admin.tab.email')}}</th>
                    <th class="no-hover">{{ $t('admin.tab.birthday')}}</th>
                    <th class="no-hover">{{$t('admin.tab.pathology')}}</th>
                    <th>{{ $t('admin.tab.action')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, idx) in usersFilter " :key="idx">
                    <!-- <td>#{{ item.id }}</td> -->
                    <td :class="{'first-connection' : item.isFirstConnection === 1}">{{ item.lastname }}</td>  
                    <td :class="{'first-connection' : item.isFirstConnection === 1}">{{ item.firstname }}</td>                                      
                    <td>{{ item.email }} <br/><span v-if="item.phone">{{ item.phone }}</span></td>
                    <td>{{ new Date(item.birthday).toLocaleDateString('fr-FR') }}</td>
                    <td class="monitoring-td">
                        <div class="monitoring-container" >
                            <div class="monitoring-item" v-for="(pat, idy) in item.pathology" :key="idy" >
                                <template v-if="pat.pathology_name">
                                    <div :class="['patho-item', {archived : pat.is_archived == 2}]" >
                                        <div 
                                            :class="['tab-btn', 'tab-relaunch', getRelaunchColor(pat.relaunch_date)]"
                                            v-tooltip="{ content : $t('tooltip.relaunch')}"
                                            @click="openRelaunch(pat, item)"
                                            v-if="isAdmin === 2 && item.center_filter"
                                        >
                                            <div 
                                                class="relaunch-notif"
                                                 v-if="pat.relaunch_number && pat.relaunch_number > 0"
                                            >
                                                {{ pat.relaunch_number }}
                                            </div>
                                        </div>
                                        <div class="patho-infos" v-tooltip="{ content : pat.archived_type_name ? JSON.parse(pat.archived_type_name)[currentLang] : null }">
                                            <div class="patho-line">
                                                <span class="pathology-id">[{{pat.id}}] </span>
                                                {{ `${JSON.parse(pat.pathology_name)[currentLang]}` }} 
                                                <span v-if="pat.center_name && pat.date_injection"> {{ `(à ${pat.center_name} le ${new Date(pat.date_injection).toLocaleDateString('fr-FR')})` }} </span>
                                                <span v-else-if="pat.center_name"> {{ `(à ${pat.center_name})` }} </span>
                                                <span v-else-if="pat.date_injection"> {{ `(le ${new Date(pat.date_injection).toLocaleDateString('fr-FR')})` }} </span>
                                            </div>
                                            <div class="patho-line">
                                                <span class="doctors" v-if="pat.doctor_name">{{ `${pat.doctor_name}` }}</span>
                                                <span :class="['next-step', getStepColor(pat.periods.state) ]" v-if="pat.periods && pat.periods.periods_name">{{ ` - ${JSON.parse(pat.periods.periods_name)[currentLang]}` }} <span v-if="pat.periods.mp_date">{{`(${new Date(pat.periods.mp_date).toLocaleDateString('fr-FR')})`}}</span></span>
                                                <span class="next-step" v-else> - {{$t('admin.tab.finish')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="tab-actions-container">
                            <div class="tab-actions">
                                <div 
                                    class="tab-btn tab-list"
                                    @click="goToPathologiesList(item.id)"
                                    v-tooltip="{ content : $t('tooltip.pathologyList')}"
                                >
                                </div>
                                <div 
                                    class="tab-btn tab-edit"
                                    @click="openUpdate(item)"
                                    v-if=" ( isAdmin === 2 && item.center_filter ) || isAdmin === 3"
                                    v-tooltip="{ content : $t('tooltip.updatePatient')}"
                                >
                                </div>
                                <div 
                                    class="tab-btn tab-edit-pathology"
                                    @click="openUpdatePathology(item.pathology, item)"
                                    v-if=" ( isAdmin === 2 && item.center_filter ) || isAdmin === 3"
                                    v-tooltip="{ content : $t('tooltip.updatePathology')}"
                                >
                                </div>
                            </div>
                            <div class="tab-actions">
                                <div
                                    class="tab-btn tab-add"
                                    @click="addPathology(item)"
                                    v-tooltip="{ content : $t('tooltip.addPathology')}"
                                >
                                    
                                </div>
                                <div
                                    :class="['tab-btn', 'tab-pdf', { 'no-agreement' : !item.agreements }]"
                                    @click="openAgreement(item.id)"
                                    v-tooltip="{ content : $t('tooltip.agreement')}"
                                >
                                </div>
                                <div
                                    v-if="isAdmin === 2 && item.center_filter"
                                    class="tab-btn tab-delete"
                                    @click="confirmModal(item.id, item.firstname, item.lastname)"
                                    v-tooltip="{ content : $t('tooltip.deletePatient')}"
                                >
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <ModalConfirm
            v-if="displayModalConfirm"
            :contentModal="$t('admin.tab.confirmDeleteUser', {userName : contentModalConfirm })"
            v-on:close="closeConfirm"
            v-on:confirm="confirmDelete"
        >
        </ModalConfirm>
        <UpdatePatient
            v-if="displayModalUpdate"
            v-on:close="closeUpdate"
            v-on:updatePatient="updatePatient"
            :user="userUpdate"
        >
        </UpdatePatient>

        <AddPathology
            v-if="displayModalAdd"
            v-on:close="closeAdd"
            v-on:updatePathology="updatePathology"
            :user="userAdd"
        >
        </AddPathology>

        <UpdatePathology
            v-if="displayUpdatePathology"
            v-on:close="closeUpdatePathology"
            v-on:updateNotif="updateNotif"
            v-on:updateStade="updateStade"
            v-on:updateArea="updateArea"
            v-on:deletePathology="deletePathology"
            v-on:changeWeightOrSize="changeWeightOrSize"
            :pathology="pathology"
            :user="userUpdatePathology"
        >
        </UpdatePathology>

        <AgreementForm
            v-if="agreementDisplay"
            :id="agreementId"
            v-on:closeAgreement="closeAgreement"
            v-on:updateAgreement="updateAgreement"
        >           
        </AgreementForm>

        <relaunch-main
            v-if="displayRelaunch"
            :patho="patRelaunch"
            :user="userRelaunch"
            v-on:closeModal="closeRelaunch"
        ></relaunch-main>
    </div>
</template>

<script>
import store from './../../store';
import axios from 'axios';
import ModalConfirm from './../ModalConfirm';
import UpdatePatient from './../forms/UpdatePatient';
import AddPathology from './../forms/AddPathology';
import UpdatePathology from './../forms/UpdatePathology';
import AgreementForm from './../forms/AgreementForm';
import Export from './../Export';
import RelaunchMain from "./../relaunch/RelaunchMain.vue";

export default {
    name: 'PatientTable',
    components : {
        ModalConfirm,
        UpdatePatient,
        AddPathology,
        UpdatePathology,
        AgreementForm,
        Export,
        RelaunchMain,
    },
    props : ['users', 'doctors'],
    data() {
        return {
            displayModalConfirm : false,
            contentModalConfirm : null,
            displayModalUpdate : false,
            userUpdate : null,
            deleteId : null,
            currentSort:'lastname',
            currentSortDir:'asc',
            isAdmin : store.state.USER.role,
            displayModalAdd : false,
            userAdd : null,
            userUpdatePathology : null,
            displayUpdatePathology : false,
            pathology : null,
            filter : true,
            agreementDisplay :false,
            agreementId : null,
            anonym : 1,
            searchPatient : null,
            searchByDoctor : -1,
            searchDate : null,
            filterWaiting : false,
            filterWaitingBefore : false,
            archived : false,
            searchByMonth : false,
            displayRelaunch : false,
            patRelaunch : null,
            userRelaunch : null
        }
    },
    methods : {
        getPathologies(item) {
            let pathologies  = '';
            item.forEach(element => {
                pathologies += element.name + '<br />' 
            });
            return pathologies.substring(0, pathologies.length - 6 )
        },
        getCenters(item) {
            let centers  = '';
            item.forEach(element => {
                centers += element.center + '<br />' 
            });
            return centers.substring(0, centers.length - 6 )
        },
        sortBy(param) {
            if(param === this.currentSort) {
                this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = param;

            this.users = this.users.sort((a,b) => {
                let modifySortDir = 1;
                if(this.currentSortDir === 'desc') modifySortDir= -1;
                if(a[param] < b[param]) return -1 * modifySortDir;
                if(a[param] > b[param]) return 1 * modifySortDir;
                return 0;
            });
        },
        sortClasses(param) {
            if(param == this.currentSort) {
                if(this.currentSortDir === 'desc') {
                    return 'sort-desc'
                }else {
                    return 'sort-asc'
                }
            }
            else {
                return 
            }            
        },
         confirmModal(id, firstname, lastname) {
            this.displayModalConfirm =true;
            this.contentModalConfirm = firstname + ' ' + lastname ;
            this.deleteId = id
        },
        closeConfirm() {
            this.displayModalConfirm = false;
            this.contentModalConfirm = null;
            this.deleteId = null;
        },
        confirmDelete() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .delete(store.getters.getURL +"users/"+ this.deleteId, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.$emit('deletePatient', vm.deleteId)
                        vm.closeConfirm();
                        store.dispatch('addNotif', {type : 1, content : 3})
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
        },
        openUpdate(user) {
            this.userUpdate = user;
            this.displayModalUpdate = true;
        },
        updatePatient() {
            this.displayModalUpdate = false
            this.$emit('updatePatient')
        },
        closeUpdate() {
            this.userUpdate = null;
            this.displayModalUpdate = false;
        },
        closeAdd() {
            this.displayModalAdd = false;
            this.userAdd = null;
        },
        addPathology(user) {
            this.displayModalAdd = true;
            this.userAdd = user;
        },
        updatePathology(id, pathology) {
            this.displayModalAdd = false;
            this.$emit('updatePathology', id,  pathology)
        },
        openUpdatePathology(pathology, user) {
            if(store.state.USER.infos.role_id === 2) {
                this.pathology = pathology.filter(e => e.center === store.state.USER.infos.centers_id)
            }
            else {
                this.pathology = pathology
            }
            this.pathology = pathology
            this.displayUpdatePathology = true
            this.userUpdatePathology = user
        },
        closeUpdatePathology() {
            this.pathology = null
            this.displayUpdatePathology = false
            this.userUpdatePathology  = null
        },
        updateNotif(id, infos) {
            this.$emit('updateNotif', id,  infos)
        },
        updateStade(id, infos) {
            this.$emit('updateStade', id,  infos)
        },
        updateArea() {
            this.$emit('updateArea')
        },
        deletePathology() {
            this.$emit('deletePathology')  
            this.displayUpdatePathology = false  
            this.userUpdatePathology  = null
    
        },
        goToPathologiesList(id) {
            if(store.state.USER.infos.role_id === 2) {
                this.$router.push({ name : 'PatologyListCoordinator', params : { id }})
            }
            if(store.state.USER.infos.role_id === 3) {
                this.$router.push({ name : 'PatologyListDoctor', params : { id }})
            }
        },
        openAgreement(id) {
            this.agreementDisplay = true
            this.agreementId = id
        },
        closeAgreement() {
            this.agreementDisplay = false
            this.agreementId = null
        },
        getStepColor(state) {     
            if(state === 1) {
                return 'step-success';
            }else if(state === 3) {
                return 'step-waiting';
            }else if(state === 4){
                return 'step-alert';
            }else {
                return null;            }
            
  
        },
        updateAgreement() {
            this.$emit('updateAgreement')
        },
        changeWeightOrSize() {
            this.$emit('changeWeightOrSize')
        },
        changefilter() {
            this.filter = !this.filter;
            this.filterWaiting = false;
            this.archived = false;
            this.filterWaitingBefore = false;
        },
        changefilterWaiting() {
            this.filter = false;
            this.filterWaiting = !this.filterWaiting;
            this.archived = false;
            this.filterWaitingBefore = false;
        },
        changeArchived() {
            this.filter = false;
            this.filterWaiting = false;
            this.archived = !this.archived;
            this.filterWaitingBefore = false;
        },
        changeFilterBefore() {
            this.filter = false;
            this.filterWaiting = false;
            this.archived = false;
            this.filterWaitingBefore = !this.filterWaitingBefore;
        },
        getRelaunchColor(date) {
            //site date > 3 jours on affiche la notif en rouge
            if(date && ( ( ( new Date().getTime() - new Date(date).getTime() ) / (1000 * 3600 * 24) ) > 3 )) {
                return 'relaunch-alert';
            }
        },
        openRelaunch(pat, user) {
            this.displayRelaunch = true;
            this.patRelaunch = pat;
            this.userRelaunch = user.firstname + " " + user.lastname;
        },
        closeRelaunch() {
            this.displayRelaunch = false;
            this.patRelaunch = null;
            this.userRelaunch = null;
            this.$emit('updateNotif');
        }





    },
    computed : {
        usersFilter() {
            // if(store.state.USER.infos.role_id === 2) {
                let filterUser = this.users;
                if(this.archived) {
                    filterUser = filterUser.filter(e => e.pathology.some(o => o.is_archived == 2) )
                }else {
                    filterUser = filterUser.filter(e => e.pathology.some(o => o.is_archived == 1) )
                }
                if( this.searchByDoctor != -1 && store.state.USER.infos.role_id === 2){
                    filterUser = filterUser.filter(e => e.pathology.some(o => o.doctor == this.searchByDoctor) )
                }
                if(this.filter && store.state.USER.infos.role_id === 2) {
                    filterUser = filterUser.filter(e => e.center_filter === true) 
                }
                if(this.searchPatient) {
                    filterUser = filterUser.filter(e => e.lastname.toLowerCase().includes(this.searchPatient.toLowerCase() ) || e.pathology.some(o => o.pathology_name.toLowerCase().includes(this.searchPatient.toLowerCase() ) ) )
                }
                if(this.searchDate) {
                    console.log('search date => ', this.searchDate.slice(0, -3))
                    if(!this.searchByMonth) {
                        filterUser = filterUser.filter(e => e.pathology.some(o => o.date_injection == this.searchDate));
                    } else {
                        filterUser = filterUser.filter(e => e.pathology.some(o => o.date_injection != null &&  (o.date_injection.slice(0, -3) == this.searchDate.slice(0,-3))));
                    }
                }
                if(this.filterWaiting) {
                    filterUser = filterUser.filter(e => e.pathology.some(  o =>  ( o.periods.state === 4 || o.periods.state === 3 ) &&  ( o.periods.periods_id != 2 && o.periods.periods_id != 1 ) && o.center == store.state.USER.infos.centers_id )  ) // on ne veut pas les M0 et avant injection
                }

                if(this.filterWaitingBefore) {
                    filterUser = filterUser.filter(e => e.pathology.some(  o =>  ( o.periods.state === 4 || o.periods.state === 3 ) &&  o.periods.periods_id == 1 && o.center == store.state.USER.infos.centers_id )  ) // on ne veut que les avant injection
                }

                return filterUser

                // if(this.filter && this.searchPatient){
                //     let filterUser = this.users.filter(e => e.center_filter === this.filter)
                //     return filterUser.filter(e => e.lastname.toLowerCase().includes(this.searchPatient.toLowerCase() ) )
                // }else if(this.searchPatient) {
                //      return this.users.filter(e => e.lastname.toLowerCase().includes(this.searchPatient.toLowerCase() ) )
                // }else if(this.filter) {
                //     return this.users.filter(e => e.center_filter === this.filter)
                // }else if( this.searchByDoctor != -1){
                //     return this.users.filter(e => e.pathology.some(o => o.doctor == this.searchByDoctor) )
                // }else {
                //     return this.users
                // }
            // }else {
            //     return this.users
            // }
        },
        currentLang() {
            return this.$store.state.LANG
        }
    }
 
}
</script>

<style lang="scss" scoped>
    .filter-patient {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top:10px;
    }

    .filter-patient .input-default, .patient-number-container .input-default {
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    input[type="checkbox"] {
        width:18px;
        min-width:18px;
        height:18px;
        border-radius:2px;
        border:1px solid $mainColor;
        background: #fff;
        margin-right:10px;
        cursor: pointer;
        padding:0;
    }

    
    input[type="checkbox"]:checked {
        background: $mainColor;
    } 

    .export-row {
        display:flex;
        justify-content: flex-end;
        margin-top:10px;
    }

    .first-connection {
        color:$error;
    }

    .tab-pdf.no-agreement {
        background-image: url('./../../assets/icon-pdf-ko.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .tab-pdf {
        background-image: url('./../../assets/icon-pdf-ok.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .filter-container {
        display: flex;
        align-items: center;
    }

    .input-search {
        padding: 10px 15px;
        background: #f1f1f1;
        border-radius: 10px;
        font-size: 15px;
        margin-right:20px;
        border:0;
    }

    select.input-search {
        padding:9px 15px;
    }

    .tab-actions {
        margin:5px 0;
    }

    // input[type="date"]:before {
    //     display: none;
    // }

    input[type="date"] {
        min-width: 300px;
    }

    .step-success {
        color:$success;
        font-family: $fontMedium;
    }

    .step-alert {
        color:$error;
        font-family: $fontMedium
    }

    .step-waiting {
        color:#0099cc;
        font-family: $fontMedium
    }

    .archived {
        cursor: pointer;
    }

    .archived, .archived .next-step {
        color:#ccc;
        font-family: $fontMedium
    }

    .monitoring-item {
        border-top:1px solid $mainColor;
        margin-top:8px;
        padding-top: 8px;
    }

    .monitoring-item:first-child {
        border:none;
        margin:2px 0;
        padding:0;
    }

    .monitoring-td {
        text-align: left;
    }

    .patient-number-container{
        margin-top:10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .patient-number {
        font-weight: 700;
        margin-left:3px;
    }

    .patient-number-container .input-default {
        margin-left:40px;
    }

    .patient-number-text {
        min-width:180px;
    }

    .patient-number-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .patient-filter-box {
        display: flex;
        justify-content: flex-start;
    }

    .patient-number-item {
        display: flex;
    }

    .patient-filter-box .input-default:first-child {
        margin-left:0;
    }

    .patient-filter-box .input-default {
        width:auto;
    }

    input[type="checkbox"].input-month {
        margin-left:-10px;
        margin-right: 30px;
    }

    .patho-item {
        display: flex;
        align-items: center;
    }

    .patho-line {
        padding:2px 0;
    }

    .patho-infos {
        margin-left:10px;
    }


</style>