<template>
     <div :class="['modal', isSafari()]" @click="closeModal">
        <div class="modal-close" @click="closeModal"></div>
        <div class="modal-container" @click.stop>
            <h3 class="patient-name">{{`${user.firstname} ${user.lastname}` }}</h3>
            <div class="form-add">
                <PathologyForm
                    v-for="(item, idx) in pathology" :key="idx"
                    :pathologyUpdate="item"
                    :user="pathology"
                    v-on:updateNotif="updateNotif"
                    v-on:updateStade="updateStade"
                    v-on:updateArea="updateArea"
                    v-on:deletePathology="deletePathology"
                    v-on:activatePathology="activatePathology"
                    v-on:archivePathology="archivePathology"
                    v-on:changeWeightOrSize="changeWeightOrSize"
                    :pathologyList="pathologyList"
                    :archivedList="archivedList"
                >
                </PathologyForm>
            </div>
        </div>
     </div>
</template>

<script>
import PathologyForm from './PathologyForm.vue'
import store from './../../store'
import axios from 'axios'

export default {
    components: { PathologyForm },
    name : "UpdatePathology",
    props : ['pathology', 'user'],
    data() {
        return {
            pathologyList : [],
            archivedList : []
        }
    },
    methods : {
        closeModal() {
            this.$emit('close')
        },
        updateNotif(id, infos) {
            this.$emit('updateNotif', id, infos)
        },
        updateStade(id, infos) {
            this.$emit('updateStade', id, infos)
        },
        updateArea() {
            this.$emit('updateArea')
        },
        deletePathology() {
            this.$emit('deletePathology')
        },
        isSafari() {
            if(/apple/i.test(navigator.vendor)) {
                return 'is-safari'
            }
        },
        changeWeightOrSize() {
            this.$emit('changeWeightOrSize')
        },
        activatePathology() {
            this.$emit('deletePathology')
        },
        archivePathology() {
            this.$emit('deletePathology')
        },
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this
        axios
            .get(store.getters.getURL +"pathology", config)
            .then(function (response) {
                vm.pathologyList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
        
        axios
            .get(store.getters.getURL +"archived", config)
            .then(function (response) {
                vm.archivedList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .modal-container {
        max-height: 90%;
        max-width:1000px;
        width:90%;
    }

    .form-add {
        max-height: 90vh;
    }

    h3.patient-name {
        color:$textGrey;
        font-size:20px;
        font-weight:700;
        text-transform:uppercase;
        margin:20px auto;
        width: 90%;
        text-align: center;
    }
</style>