<template>
    <div class="modal" @click="$emit('closeAgreement')">
        <div class="modal-close" @click="$emit('closeAgreement')"></div>
        <Loader v-if="loading"></Loader>
        <div class="modal-container" @click.stop v-else>
            <h2>{{ $t('patient.form.pdfTitle')}}</h2>
            <div class="pdf-list" v-for="(item, idx) in pdfList" :key="idx">
                <div class="pdf-name" @click="downloadFile(item.agreements_id, item.agreements_name)">{{ item.agreements_name}}</div>
                <div class="pdf-delete-container">
                    <div class="pdf-delete" @click="destroyFile(item.agreements_id, item.agreements_name)"></div>
                </div>
            </div>
            <div class="pdf-add">
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form @submit.prevent="handleSubmit(submit)">
                        <div class="row">
                            <div class="col50">
                                <ValidationProvider  class="input-default"  rules="required|max:250" v-slot="{ errors }">
                                    <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="pdfName" name="pdf-name" :placeholder=" $t('patient.form.pdfName')" />
                                    <span class="notice-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="col50">
                                <ValidationProvider  class="input-default input-file"  rules="mimes:application/pdf|size" v-slot="{ errors }">
                                    <input type="file"  ref="file" name="pdf-file" :class="{ 'input-error' : errors[0]}" />
                                    <span class="notice-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="submit-row">
                            <input type="submit" class="btn" :value="$t('admin.form.addBtn')" /> 
                        </div> 
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <ModalConfirm
            v-if="displayModalConfirm"
            :contentModal="$t('patient.form.confirmDeleteFile', {fileName : pdfFileName })"
            v-on:close="closeConfirm"
            v-on:confirm="confirmDelete"        >
        </ModalConfirm>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import store from './../../store'
import axios from 'axios'
import Loader from './../../components/Loader'
import ModalConfirm from './../../components/ModalConfirm'

export default {
    name : 'AgreementForm',
    data() {
        return {
            pdfList : [],
            pdfName : null,
            loading :false,
            pdfFileName :null,
            displayModalConfirm : false,
            deleteId : null
        }
    },
    props: ['id'],
    components : {
      ValidationProvider,
      ValidationObserver,
      Loader,
      ModalConfirm
    },
    methods : {
        submit() {
            let pdf = this.$refs.file.files[0]
            let formData = new FormData();
            formData.append('file', pdf);  
            formData.append('id', this.id);
            formData.append('name', this.pdfName)  

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}`, 'content-type': 'multipart/form-data' }
            }

            let vm = this
            this.loading = true
            axios
                .post(store.getters.getURL+'agreement', formData , config)
                .then(function(response) {
                    if(response.status === 200) {
                           
                        vm.pdfList.push({
                            agreements_name : response.data.name,
                            agreements_id : response.data.id,
                        })
                        vm.pdfName = null
                         store.dispatch('addNotif', {type : 1, content : 26})
                        vm.$emit('updateAgreement')
                        vm.loading = false                        
                    }else {
                        vm.pdfName = null
                         store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                         vm.loading = false
                    }
                })
                .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                        vm.loading = false
                    });
        },
        downloadFile(id, name) {
            let config = {
                validateStatus: () => true,
                responseType: 'blob',
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            this.pdfFileName = name

            let vm = this
             axios
                .get(store.getters.getURL+'agreement/download_pdf/' + id ,  config)
                .then(function(response) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', vm.pdfFileName + '.pdf');
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                })
        },
        destroyFile(id, name) {
            this.displayModalConfirm = true
            this.pdfFileName = name
            this.deleteId = id
        },
        closeConfirm() {
            this.displayModalConfirm = false
            this.pdfFileName = null
            this.deleteId = null
        },
         confirmDelete() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .delete(store.getters.getURL+'agreement/' + this.deleteId , config)
                .then(function(response) {
                    if(response.status === 200) {   
                        store.dispatch('addNotif', {type : 1, content : 21}) 
                        vm.pdfList = vm.pdfList.filter(e => e.agreements_id != vm.deleteId)                   
                        vm.pdfFileName = null
                        vm.deleteId =  null 
                        vm.displayModalConfirm = false  
                        vm.$emit('updateAgreement')
                    }
                })
                .catch((err) => {
                        store.dispatch('addNotif', {type : 2, content : err})
                    });
        }
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"agreement/" + this.id, config)
            .then(function (response) {
                vm.pdfList = response.data.list
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style scoped lang="scss">

    .modal-container {
        max-height: 90%;
        max-width:1000px;
        width:90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        font-size:20px;
        letter-spacing: 1px;
        color:$mainColor;
        font-family: $fontBlack;
        text-transform:uppercase;
        margin-bottom:40px;
        margin-top:40px;
    }

    .pdf-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom:10px;
        width:800px;
    }

    .pdf-delete {
        height:30px;
        width:30px;
        background-image: url('./../../assets/icon-delete.png');
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .pdf-add{
        display: flex;
        align-items: center;
        margin:20px 0;
        width:800px;
        border-top:1px solid $mainColor;
        padding-top:20px;
    }

    .pdf-add form, .pdf-add span {
        width:100%;
    }

    .pdf-add .col50 {
        width:42%;
        margin:0 4%;
    }

    .sep-agreement {
        width: 80%;
        height:1px;
        background:$mainColor;
        margin:20px auto;
    }

    .input-file input {
        padding: 12px 20px;
    }

    .submit-row {
        display: flex;
        justify-content: center;
        margin-top:20px,
    }

    .pdf-name {
        width:55%;
        text-align: right;
        margin-right:2%;
        color:$mainColor;
        text-transform: uppercase;
        font-size:18px;
        cursor: pointer;
    }

    .pdf-name:hover {
        text-decoration: underline;
    }

    .pdf-delete-container {
        width:43%;
    }
</style>