<template>
    <div class="modal-container">
        <h2>{{ user }}</h2>
        <div class="pathologie-sep">
            <h3><span>[{{patho.id }}] </span>{{ JSON.parse(patho.pathology_name)[this.$store.state.LANG] }} </h3>
            <div class="pathologie-line"></div>
        </div>  
        <div class="relaunch-tab">
            <div class="relaunch-bar">
                <div class="btn" @click="addRelaunch(patho.id)">{{ $t('admin.relaunch.addBtn')}}</div>
            </div>
            <table v-if="relaunchList && relaunchList.length > 0">
                <thead>
                    <tr>
                        <th>{{ $t('admin.relaunch.date')}}</th>
                        <th>{{ $t('admin.relaunch.period')}}</th>
                        <th>{{ $t('admin.relaunch.call')}}</th>
                        <th>{{ $t('admin.relaunch.sms')}}</th>
                        <th>{{ $t('admin.relaunch.message')}}</th>
                        <th>{{ $t('admin.relaunch.comment')}}</th>
                        <th>{{ $t('admin.relaunch.actions')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in relaunchList" :key="idx">
                        <td>{{ new Date(item.relaunch_date).toLocaleDateString() }}</td>
                        <td>{{ item.relaunch_period }}</td>
                        <td>
                            <div v-if="item.relaunch_call" class="relaunch-done"></div>
                        </td>
                        <td>
                            <div v-if="item.relaunch_sms" class="relaunch-done"></div>
                        </td>
                        <td>
                            <div v-if="item.relaunch_message" class="relaunch-done"></div>
                        </td>
                        <td>{{ item.relaunch_comment }}</td>
                        <td>
                            <div class="tab-actions">
                                <div
                                    class="tab-btn tab-edit"
                                    @click="openUpdate(item)"
                                    v-tooltip="{ content : $t('tooltip.updateRelaunch')}"
                                >
                                </div>
                                <div                                    
                                    class="tab-btn tab-delete"
                                    @click="confirmModal(item.relaunch_id)"
                                    v-tooltip="{ content : $t('tooltip.deleteRelaunch')}"
                                >
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="no-data" v-else>{{ $t('admin.relaunch.noData')}}</p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";

export default {
    name : "RelaunchTab",
    props : ["patho", "user"],
    data() {
        return {
            relaunchList : []
        }
    },
    methods : {
        addRelaunch(id) {
            this.$emit('addRelaunch', id);
        },
        openUpdate(item) {
            this.$emit('updateRelaunch', item);
        },
        confirmModal(id) {
            this.$emit('deleteRelaunch', id);
        },
        getData() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .get(store.getters.getURL +"relaunch/" + this.patho.id , config)
                .then(function (response) {
                    vm.isLoading = false
                    if(response.status === 200) {
                        vm.relaunchList = response.data;
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }
    },
    mounted() {
        this.getData();
    }
}
</script>

<style lang="scss" scoped>
    .modal-container {
        min-height:80vh;
        width:1600px;
    }

    .pathologie-sep {
        height:20px;
        margin:20px auto;
        width:80%;
        display: flex;
        justify-content: center;
        position:relative
    }

    .pathologie-sep h3 {
        color:$mainColor;
        font-size:18px;
        text-transform: uppercase;
        font-family: $font;
        background:#fff;
        text-align: center;
        display: inline-block;
        position: relative;
        z-index: 99;
        padding:0 20px;
    }

    .pathologie-line {
        height:1px;
        width: 100%;
        position:absolute;
        top:50%;
        background:$mainColor;
    }

    h2 {
        color: $textGrey;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 40px auto;
        width: 90%;
        text-align: center;
    }

    .relaunch-tab {
        margin:40px;
    }

    .relaunch-bar {
        display: flex;
        justify-content: flex-start;
    }

    .btn {
        width:150px;
    }

    .relaunch-done {
        width:20px;
        height:20px;
        background: $mainColor;
        border-radius:50%;
        margin:0 auto;
    }

    .no-data {
        color:$textGrey;
        width: 100%;
        text-align: center;
        margin:80px 20px;
        font-size:20px;
    }
</style>

