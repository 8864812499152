<template>
    <div class="modal" @click="closeModal">
        <div class="modal-close" @click="closeModal"></div>
        <div class="relaunch-container" @click.stop>
            <relaunch-add
                v-if="display === 2"
                :pathoId="pathoId"
                v-on:addRelaunch="closeModal"
            >
            </relaunch-add>
            <relaunch-update
                v-else-if="display === 3"
                :relaunch="relaunchItem"
                v-on:updateRelaunch="closeModal"
            >
            </relaunch-update>
            <relaunch-delete
                v-else-if="display === 4"
                :pathoId="pathoId"
                v-on:closeModal="closeModal"
                v-on:deleteRelaunch="closeModal"
            >
            </relaunch-delete>
            <relaunch-tab
                v-else 
                :patho="patho"
                :user="user"    
                v-on:addRelaunch="addRelaunch"    
                v-on:updateRelaunch="updateRelaunch"    
                v-on:deleteRelaunch="deleteRelaunch"    
            >
            </relaunch-tab>
        </div>
    </div>
</template>

<script>
import RelaunchTab from "./RelaunchTab.vue";
import RelaunchAdd from "./RelaunchAdd.vue";
import RelaunchUpdate from "./RelaunchUpdate.vue";
import RelaunchDelete from "./RelaunchDelete.vue";

export default {
    name: "RelaunchMain",
    props : ["patho", "user"],
    components : {
        RelaunchTab,
        RelaunchAdd,
        RelaunchUpdate,
        RelaunchDelete
    },
    data() {
        return {
            display : 1, //affiche le component => 1 = tab, 2 => add, 3 => update, 4 => delete 
            pathoId : null,
            relaunchItem : null
        }
    },
    methods : {
        closeModal() {
            if(this.display === 1) {
                this.$emit('closeModal');
            } else {
                this.display = 1;
            }
        },
        addRelaunch(id) {
            this.pathoId = id;
            this.display = 2;
        },
        updateRelaunch(item) {
            this.relaunchItem = item;
            this.display = 3;
        },
        deleteRelaunch(id) {
            this.pathoId = id;
            this.display = 4;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>