<template>
  <div class="modal" @click="closeModal">
        <div class="modal-close" @click="closeModal"></div>
        <div class="modal-container" @click.stop>
            <div class="form-add">
                <h3 class="patient-name">{{`${user.firstname} ${user.lastname}` }}</h3>
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                    <form @submit.prevent="handleSubmit(submit)">
                        <PathologyForm
                            v-on:changeDoctor="changeDoctor"
                            v-on:changeCenter="changeCenter"
                            v-on:changePathology="changePathology"
                            v-on:changeStep="changeStep"
                            v-on:changeSize="changeSize"
                            v-on:changeWeight="changeWeight"
                            v-on:changeImc="changeImc"
                            v-on:changeNotif="changeNotif"
                            v-on:changeArea="changeArea"
                            v-on:addArea="addArea"
                            :pathologyList="pathologyList"
                        >
                        </PathologyForm>
                        
                        <input type="submit" class="input-submit" :value="$t('admin.form.addBtn')" /> 

                    </form>
                 </ValidationObserver>
            </div>
        </div>
        </div>  
</template>

<script>
import PathologyForm from './PathologyForm'
import { ValidationObserver } from 'vee-validate';
import store from './../../store'
import axios from 'axios'

export default {
    components: { PathologyForm,ValidationObserver },
    name: 'AddPathology',
    props: ['user'],
    data() {
        return {
            pathology : {
                notif:false,
                stepLeft :null,
                stepRight : null,
                imc:null,
                size:1,
                weight:1,
                area:null,
                doctor:-1,
                center:-1,
                pathologyId:-1,
                
            },
            pathologyList : []
        }
    },
    methods : {
        closeModal() {
            this.$emit('close')
        },
        submit() {
            let params = this.pathology
            params.user = this.user.id
            params.birthday = this.user.birthday

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .post(store.getters.getURL +"monitoring", params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.$emit('updatePathology', vm.user.id, response.data.pathology )
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
        },
        changeDoctor(doctor) {
            this.pathology.doctor = doctor
        },
        changeCenter(center) {
            this.pathology.center = center
        },
        changePathology(pathology) {
            this.pathology.pathologyId = pathology
        },
        changeStep(step) {
            this.pathology.stepLeft = step.stepLeft
            this.pathology.stepRight = step.stepRight
        },
        changeSize(size) {
            this.pathology.size = size
        },
        changeWeight(weight) {
            this.pathology.weight = weight
        },
        changeImc(imc) {
            this.pathology.imc = imc
        },
        changeNotif(notif) {
            this.pathology.notif = notif
        },
        changeArea(area) {
            this.pathology.area = area
        },
        addArea(area) {
            this.pathology.area = area
        },
    },
    mounted() {

        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this
        axios
            .get(store.getters.getURL +"pathology", config)
            .then(function (response) {
                vm.pathologyList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .form-add {
        margin-top:20px;
    }

    .modal-container {
        max-height: 90%;
        max-width:1000px;
        width:90%;
    }

    h3.patient-name {
        color:$textGrey;
        font-size:20px;
        font-weight:700;
        text-transform:uppercase;
        margin:20px auto;
    }
</style>


