<template>
    <div class="modal" @click="closeModal">
        <div class="modal-close" @click="closeModal"></div>
        <div class="modal-container" @click.stop >
            <div class="form-add">
            <h2>{{ $t('admin.form.updatePatientTitle')}}</h2>
            <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
            <div class="row">
                    <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50" v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="firstname" :placeholder=" $t('admin.form.firstname')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50"  v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="lastname" :placeholder=" $t('admin.form.lastname')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50|email" vid="email" v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="email" :placeholder=" $t('admin.form.email')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required|max:50|email|confirmed:email"  v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="emailConfirm" :placeholder=" $t('admin.form.emailConfirm')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col50">
                            <ValidationProvider  class="input-default"  v-slot="{ errors }">
                                <input type="date" :class="{ 'input-error' : errors[0]}"  v-model="birthday" name="birthday" :placeholder=" $t('patient.form.birthday')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col50">
                            <ValidationProvider  class="input-default"  rules="required" v-slot="{ errors }">
                                <select v-model="sex" :class="{ 'input-error' : errors[0]}" name="sex">
                                    <option value="-1" disabled>{{ $t('patient.form.sex') }}</option>
                                    <option value="2">Femme</option> 
                                    <option value="1">Homme</option>
                                </select>
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <!-- <div class="col33">
                            <ValidationProvider  class="input-default"  v-slot="{ errors }">
                                <select v-model="status" :class="{ 'input-error' : errors[0]}" name="statut">
                                    <option value="-1" disabled>{{ $t('patient.form.situation') }}</option>
                                    <option v-for="(item, idx) in statusList" :key="idx" :value="item.marital_status_id">{{ JSON.parse(item.marital_status_name)[currentLang]}}</option> 
                                </select>
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div> -->
                    </div>  

                    <div class="row">
                        <div class="col50">
                            <ValidationProvider  class="input-default" rules="max:25" v-slot="{ errors }">
                                <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="phone" name="phone" :placeholder=" $t('patient.form.phone')" />
                                <span class="notice-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <input type="submit" class="input-submit" :value="$t('admin.form.updateBtn')" />                
            </form>
            </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import axios from 'axios'
import store from './../../store'

export default {
    name : 'UpdatePatient',
    props : ['user'],
    components : {
      ValidationProvider,
      ValidationObserver
    },
    data() {
        return {
            firstname : null,
            lastname : null,
            email :null,
            emailConfirm : null,
            sex : null,
            id : null,
            birthday : null,
            // status : null,
            statusList :  [],
            phone : null
        }
    },
    methods : {
        closeModal() {
            this.$emit('close')
        },
        submit() {
            let params = {
                firstname : this.firstname,
                lastname : this.lastname,
                email :this.email,
                emailConfirm : this.emailConfirm,
                sex : this.sex,
                id : this.id,
                phone : this.phone,
                role_id : 4,
                // marital_status : this.status,
                birthday : this.birthday,
                // statusList  : []
            }

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .put(store.getters.getURL +"users/" + this.id, params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.$emit('updatePatient')
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
        }
    },
    mounted() {
        this.firstname = this.user.firstname;
        this.lastname = this.user.lastname;
        this.email = this.user.email;
        this.emailConfirm = this.user.email;
        this.id = this.user.id;
        // this.status = this.user.marital_status
        this.birthday = this.user.birthday;
        this.sex = this.user.sex;
        this.phone = this.user.phone;


        //récupération des status maritaux
        // let config = {
        //      validateStatus: () => true,
        // }
        // if(store.getters.getToken) {
        //     config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        // }

        // let vm = this

        // axios
        //     .get(store.getters.getURL +"marital_status", config)
        //     .then(function (response) {
        //         vm.statusList = response.data
        //     })
        //     .catch((err) => {
        //         store.dispatch('addNotif', {type : 2, content : err})
        //     });
    },
    computed : {
        currentLang() {
            return this.$store.state.LANG
        }
    }
    
}
</script>

<style lang="scss" scoped>
    .form-add h2 {
        margin-top: 40px;
    }

    .modal-container {
        max-height: 90%;
        max-width:1000px;
        width:90%;
    }

    
</style>