<template>
    <div class="modal-container">        
        <h2>{{ $t('admin.relaunch.update')}}</h2>
        <div class="form-add" v-if="!isLoading">
            <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="row">
                    <div class="col50">
                        <ValidationProvider  class="input-default"   v-slot="{ errors }">
                            <label>{{ $t('admin.relaunch.date') }}</label>
                            <input type="date" :class="{ 'input-error' : errors[0]}"  v-model="date" :placeholder=" $t('admin.relaunch.date')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col50">
                        <ValidationProvider  class="input-default"  rules="max:50"  v-slot="{ errors }">
                            <label>{{ $t('admin.relaunch.period') }}</label>
                            <input type="text" :class="{ 'input-error' : errors[0]}"  v-model="period" :placeholder=" $t('admin.relaunch.period')" />
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col33">
                        <ValidationProvider  class="input-default"   v-slot="{ errors }">
                            <div class="ckeckbox-container">
                                <input type="checkbox"
                                    v-model="call"
                                    :placeholder="$t('admin.relaunch.call')"
                                    class="{ 'input-error' : errors[0]}"                           
                                />
                                <label>{{ $t('admin.relaunch.call') }}</label>
                            </div>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col33">
                        <ValidationProvider  class="input-default"   v-slot="{ errors }">
                            <div class="ckeckbox-container">
                                <input type="checkbox"
                                    v-model="sms"
                                    :placeholder="$t('admin.relaunch.sms')"
                                    class="{ 'input-error' : errors[0]}"                           
                                />
                                <label>{{ $t('admin.relaunch.sms') }}</label>
                            </div>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="col33">
                        <ValidationProvider  class="input-default"   v-slot="{ errors }">
                            <div class="ckeckbox-container">
                                <input type="checkbox"
                                    v-model="message"
                                    :placeholder="$t('admin.relaunch.message')"
                                    class="{ 'input-error' : errors[0]}"                           
                                />
                                <label>{{ $t('admin.relaunch.message') }}</label>
                            </div>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col100">
                        <ValidationProvider  class="input-default" rules="max:500"   v-slot="{ errors }">
                            <label>{{ $t('admin.relaunch.comment') }}</label>
                            <textarea :class="{ 'input-error' : errors[0]}"  v-model="comment" :placeholder=" $t('admin.relaunch.comment')" rows="5"></textarea>
                            <span class="notice-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <input type="submit" class="input-submit" :value="$t('admin.form.updateBtn')" />  
            </form>
            </ValidationObserver>
        </div>
        <div class="loader-container" v-else>
            <loader ></loader>
        </div>
    </div>
</template>

<script>
import { ValidationProvider,  ValidationObserver } from 'vee-validate';
import Loader from "./../Loader.vue";
import axios from "axios";
import store from "./../../store";

export default {
    name : "RelaunchUpdate",
    props : ["relaunch"],
    components : {
      ValidationProvider,
      ValidationObserver, 
      Loader
    },
    data() {
        return {
            date : null,
            period : null,
            comment : null,
            call: null,
            sms : null, 
            message : null,
            isLoading : false
        }
    },
    mounted() {
        this.date = this.relaunch.relaunch_date;
        this.period = this.relaunch.relaunch_period;
        this.comment = this.relaunch.relaunch_comment;
        this.call = this.relaunch.relaunch_call;
        this.sms = this.relaunch.relaunch_sms;
        this.message = this.relaunch.relaunch_message;
    },
    methods : {
        submit() {
            this.isLoading = true
        
            let params = {
                id : this.relaunch.relaunch_id,
                date : this.date,
                period : this.period,
                comment : this.comment,
                call: this.call,
                sms : this.sms, 
                message : this.message
            }

            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .put(store.getters.getURL +"relaunch", params, config)
                .then(function (response) {
                    vm.isLoading = false
                    if(response.status === 200) {
                        vm.$emit('updateRelaunch');
                        store.dispatch('addNotif', {type : 1, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
            
        }
            
        },
}
</script>

<style lang="scss" scoped>
    h2 {
        font-size: 20px;
        letter-spacing: 1px;
        color: $mainColor;
        font-family: $fontBlack;
        text-transform: uppercase;
        margin-bottom: 40px;
        margin-top: 40px;
        text-align: center;
    }

    .modal-container {
        width:800px;
    }

    .input-default input[type="checkbox"] {
        width:18px;
        height:18px;
        border-radius:2px;
        border:1px solid $mainColor;
        background: #fff;
        margin-right:20px;
        cursor: pointer;
        padding:0;
    }

    .input-default input[type="checkbox"]:checked {
        background: $mainColor;
    } 

    .ckeckbox-container {
        display: flex;
        align-items: center;
    }

    .loader-container {
        display: flex;
        justify-content: center;
        width:100%;
    }
</style>