<template>
    <div class="modal-container">
        <h2>{{ $t('admin.relaunch.delete')}}</h2>
        <p>{{ $t('admin.relaunch.deleteText')}}</p>
        <div class="modal-btn-confirm">
            <div class="btn btn-cancel" @click="closeModal">{{ $t('admin.relaunch.cancell')}}</div>
            <div class="btn btn-confirm" @click="confirmDelete">{{ $t('admin.relaunch.confirm')}}</div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";

export default {
    name : "RelaunchDelete",
    props : ["pathoId"],
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        confirmDelete() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let vm = this

            axios
                .delete(store.getters.getURL +"relaunch/" + this.pathoId , config)
                .then(function (response) {
                    vm.isLoading = false
                    if(response.status === 200) {
                        vm.$emit('deleteRelaunch');
                        store.dispatch('addNotif', {type : 1, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }else {
                        store.dispatch('addNotif', {type : 2, content : JSON.parse(response.data.message)[store.state.LANG]})
                    }
                    
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }
    }
}
</script>

<style lang="scss" scoped>
    h2 {
        font-size: 20px;
        letter-spacing: 1px;
        color: $mainColor;
        font-family: $fontBlack;
        text-transform: uppercase;
        margin-bottom: 40px;
        margin-top: 40px;
        text-align: center;
    }

    p {
        text-align: center;
    }

    .modal-container {
        width:800px;
    }

    .modal-btn-confirm {
        margin-top:40px;
        display: flex;
    }

    .modal-btn-confirm .btn {
        width:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height:50px;        
        cursor: pointer;
    }

    .btn-confirm {
        background: $mainColor;
        color:#fff;
        border:1px solid $mainColor;
        border-radius:0;
        border-bottom-right-radius: 10px;
    }

    .btn-confirm.full {
        width:100%;
        border-radius:0;
        border-bottom-left-radius: 10px;
    }

    .btn-confirm:hover {
        border:1px solid $mainColor;
        color:$mainColor; 
        background: #fff;
    }

    .btn-cancel {
        background: #fff;
        color:$inputBackgroundLogin;
        border:1px solid #fff;
        border-radius: 0;
        border-bottom-left-radius: 10px;
    }

    .btn-cancel:hover {
        border:1px solid $mainColor;
        color:$mainColor; 
    }

</style>